import * as React from "react"
import styled from 'styled-components'
import { Grid, Modal } from '@material-ui/core';
import one from '../../images/gallery/one.png'
import two from '../../images/gallery/two.png'
import three from '../../images/gallery/three.png'
import four from '../../images/gallery/four.png'
import five from '../../images/gallery/five.png'
import six from '../../images/gallery/six.png'
import withWidth from '@material-ui/core/withWidth';


const Gallery = ({width}) => {
  const [photo, setPhoto] = React.useState(null);

  return (
    <Container>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={5}>
          <Photo src={one} onClick={() => setPhotoCustom(width, setPhoto, one)}/>
        </Grid>
        <Grid item xs={7}>
          <Photo src={two} onClick={() => setPhotoCustom(width, setPhoto, two)}/>
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={5}>
          <Photo2 src={three} onClick={() => setPhotoCustom(width, setPhoto, three)}/>
        </Grid>
        <Grid item xs={7}>
          <Photo src={four} onClick={() => setPhotoCustom(width, setPhoto, four)}/>
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={6}>
          <Photo src={five} onClick={() => setPhotoCustom(width, setPhoto, five)}/>
        </Grid>
        <Grid item xs={6}>
          <Photo src={six} onClick={() => setPhotoCustom(width, setPhoto, six)}/>
        </Grid>
      </Grid>
      <Modal
        open={photo !== null && width !== "md" && width !== "lg" && width !== "xl"}
        onClose={() => setPhoto(null)}
        style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}
      >
        <ModalImage src={photo} onClick={() => setPhoto(null)}/>
      </Modal>
    </Container>
  )
}

function setPhotoCustom (width, setPhoto, photo) {
  if (width !== "md" && width !== "lg" && width !== "xl") {
    setPhoto(photo)
  }
}

export default withWidth()(Gallery)

const Container = styled.div`
  margin: 2%;
`

const Photo = styled.img`
  width: 100%;
  object-fit: contain;
`

const Photo2 = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: -15%;
`

const ModalImage = styled.img`
  object-fit: contain;

  @media only screen and (min-width: 1056px) {
    display: none;
  }

  @media only screen and (max-width: 1055px) {
    max-width: 95%;
    max-height: 95%;
  }

  @media only screen and (max-width: 500px) {
    max-width: 95%;
    max-height: 95%;
  }
`
