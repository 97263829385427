import * as React from "react"
import styled from 'styled-components'
import { Grid, Modal } from '@material-ui/core';

const Exams = () => {

  return (
    <Container>
      <UnorderedList style={{ paddingRight: "20px" }}>
        <li>
            El acceso a los resultados de sus examenes se puede realizar por medio de:
            <ul style={{ marginTop: "5px" }}>
                <li>Retiro en el laboratorio</li>
                <li>Envío directo a su correo</li>
                <li>Consulta en página web</li>
            </ul>
        </li>
      </UnorderedList>
    </Container>
  )
}

const Container = styled.div`
	width: 90%;
  margin-left: 5%;
  margin-top: 35px;
  margin-bottom: 40px;
	background-color: #000353;
  border-radius: 40px;
  color: white;
  display: flex;
  opacity: 0.85;
`

const UnorderedList = styled.ul`
  list-style-type: none;
`

export default Exams
