import * as React from "react"
import styled from 'styled-components'
import SEO from '../components/SEO.js'
import Navbar from '../components/Navbar.js'
import Footer from '../components/Footer.js'
import Header from '../components/Header.js'
import Social from '../components/Social.js'
import Exams from '../components/index/Exams.js'
import ExamsInfo from '../components/index/ExamsInfo.js'
import Backdrop from '../images/banners/inicio.jpg'
import Gallery from '../components/index/Gallery.js'

const IndexPage = () => {
  return (
    <Container>
      <SEO title="Laboratorio Clínico San Martín" description="Un nuevo estándar en calidad de atención y gestión clínica en San Felipe"/>
      <Header title="Laboratorio Clínico San Martín" image={Backdrop} subtitle="Un nuevo estándar en calidad de atención y gestión clínica en San Felipe"/>
      <Social/>
      <Exams/>
      <ExamsInfo/>
      <Gallery/>
      <Footer/>
      <Navbar/>
    </Container>
  )
}

export default IndexPage

const Container = styled.div`
  font-family: Century Gothic,CenturyGothic,sans-serif;
  margin: -8px;
  padding-top: 93px;
`
