import React from "react"
import styled from 'styled-components'
import { Grid } from '@material-ui/core';
import blood from '../../images/exams/blood.png'
import bactery from '../../images/exams/bactery.png'
import chemistry from '../../images/exams/chemistry.png'
import hormone from '../../images/exams/hormone.png'
import parasite from '../../images/exams/parasite.png'

const Exam = ({ width, title, subtitle, image }) => {

  return (
    <ExamContainer>
      <Icon src={image} alt=''/>
      <ExamDivResponsive>
        <div style={{ fontSize: 16 }}>{title}</div>
        <div style={{ fontSize: 14, maxWidth: "150px" }}>{subtitle}</div>
      </ExamDivResponsive>
    </ExamContainer>
  )
}

const ExamsInfo = () => {
  return (
    <Container>
      <ResponsiveGrid container>
        <Grid item container direction="row">
          <MarginGrid item xs={0} sm={0} md={1}/>
          <MarginGrid item xs={12} sm={4} md={2}>
            <Exam
              title="Hematología"
              image={blood}
            />
          </MarginGrid>
          <MarginGrid item xs={12} sm={4} md={2}>
            <Exam
              title="Orina y parasitología"
              image={bactery}
            />
          </MarginGrid>
          <MarginGrid item xs={12} sm={4} md={2}>
            <Exam
              title="Química clínica"
              image={chemistry}
            />
          </MarginGrid>
          <SeparatorGrid item xs={0} sm={2} md={0}/>
          <MarginGrid item xs={12} sm={4} md={2}>
            <Exam
              title="Inmunología y hormonas"
              image={hormone}
            />
          </MarginGrid>
          <MarginGrid item xs={12} sm={4} md={2}>
            <Exam
              title="Microbiología"
              image={parasite}
            />
          </MarginGrid>
        </Grid>
      </ResponsiveGrid>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;

  @media only screen and (min-width: 600px) and (max-width: 959.9px) {
    margin-bottom: 0px;
  }
`

const MarginGrid = styled(Grid)`
	margin-bottom: 25px;

  @media only screen and (min-width: 600px) and (max-width: 959.9px) {
    margin-bottom: 50px;
  }
`

const SeparatorGrid = styled(Grid)`
	margin-bottom: 25px;
  display: none;

  @media only screen and (min-width: 600px) and (max-width: 959.9px) {
    display: block;
  }
`

const ResponsiveGrid = styled(Grid)`
	text-align: center;
  display: flex;
`

const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
`

const ExamContainer = styled.div`
	display: inline-block;

  @media only screen and (max-width: 599px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
`

const ExamDivResponsive = styled.div`

  @media only screen and (max-width: 599px) {
    display: inline-block;
    margin-left: 20px;
  }
`

export default ExamsInfo
