import * as React from "react"
import styled from 'styled-components'
import { Grid, Modal } from '@material-ui/core';

const Social = () => {

  return (
    <Container>
      <Grid xs={7} justify="flex-start" alignItems="center" container style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        Visítanos en nuestras redes sociales
      </Grid>
      <SpanURL style={{ paddingTop: "40px" }}><Linked href="https://twitter.com/labosanmartin">/labosanmartin</Linked></SpanURL>
      <Grid>
        <Linked href="https://twitter.com/labosanmartin"><Icon src={require('../images/icons/twitterWhite.png')} alt=''/></Linked>
      </Grid>
      <SpanURL style={{ paddingTop: "40px" }}><Linked href="https://www.instagram.com/lcsmsf">/lcsmsf</Linked></SpanURL>
      <Grid>
        <Linked href="https://www.instagram.com/lcsmsf"><Icon src={require('../images/icons/instaWhite.png')} alt=''/></Linked>
      </Grid>
      <SpanURL style={{ paddingTop: "40px" }}><Linked href="https://facebook.com/LCSM.SanFelipe">/LCSM.SanFelipe</Linked></SpanURL>
      <Grid>
        <Linked href="https://facebook.com/LCSM.SanFelipe"><Icon src={require('../images/icons/faceWhite.png')} style={{ borderRadius: "50px", marginRight: "5px" }} alt=''/></Linked>
      </Grid>
    </Container>
  )
}

const Container = styled.div`
	width: 90%;
  height: 100px;
  margin-left: 5%;
  margin-top: 35px;
  margin-bottom: 40px;
	background-color: #000353;
  border-radius: 40px;
  color: white;
  display: flex;
  opacity: 0.85;
`

const Icon = styled.img`
  width: 60px;
  height: 60px;
  padding: 20px;

  @media only screen and (max-width: 800px) {
    width: 50px;
    height: 50px;
    padding: 10px;
    margin-top: 15px;
  }
`

const Linked = styled.a`
  color: white;

  &: hover {
    color: #f2790f;
  }
`

const SpanURL = styled.span`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export default Social
